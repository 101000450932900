.pais {
    text-align: left;
    width: 50%;
    padding-right: 0.7rem;
}

.debut {
    text-align: center;
    width: 15rem;
}

.internacional {
    text-align: center;
    width: 15rem;
    padding-right: 0.7rem;
}

.retiro {
    text-align: center;
    width: 15rem;
}
